import Client from '../axios-client';

const resource = '/subaccount/dashboard';

export default {
    getCountSubaccountAndSummarySumOperationsAmount() {
        return Client.get(`${resource}/count-subaccount-and-summary-sum-operations-amount`);
    },
    getOperationsOfLastMonths(parameters = '') {
        return Client.get(`${resource}/operations-of-last-months` + parameters);
    },
    getLastMonthOperationsBySubaccount: function () {
        return Client.get(`${resource}/get-last-months-operations-by-subaccount`);
    },
};

import Client from '../axios-client';

const resource = '/application/settlement';

export default {
  makeWithdrawal(settlementId, payload) {
    return Client.put(`${resource}/${settlementId}/make-withdrawal`, payload);
  },
  getItem(applicationId) {
    return Client.get(`${resource}?applicationId=${applicationId}`);
  }
};

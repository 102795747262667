import Client from '../axios-client';
import ClientBaseDomain from '../axios-client-base-domain'

const resource = '/core/userAccount';

export default {
    getAll() {
        return Client.get(`${resource}`);
    },
    getMyAccount() {
        return Client.get(`${resource}/myAccount`);
    },
    get(id) {
        return Client.get(`${resource}/${id}`);
    },
    create(payload) {
        return Client.post(`${resource}`, payload);
    },
    update(payload, id) {
        return Client.put(`${resource}/${id}`, payload);
    },
    changePassword(payload) {
        return Client.put(`${resource}/changePassword`, payload);
    },
    resetPassword(payload) {
        return ClientBaseDomain.put(`/resetPassword`, payload);
    },
    changePasswordWithToken(payload, token) {
        return ClientBaseDomain.put(`/changePassword/${token}`, payload);
    }
};

import Client from '../axios-client';

const resource = '/subaccount/subaccount';

export default {
    getAll() {
        return Client.get(`${resource}`);
    },
    getGrid(parameters) {
        return Client.get(`${resource}/grid` + parameters);
    },
    getQuickSearch() {
        return Client.get(`${resource}/quickSearch`);
    },
    getExcel: function () {
        return Client.get(`${resource}/excel`,
            {
                responseType: 'blob',
                headers: { 'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }
            }
        )},
    getItem(id) {
        return Client.get(`${resource}/${id}`);
    },
    add(payload) {
        return Client.post(`${resource}`, payload);
    },
    update(payload, id) {
        return Client.put(`${resource}/${id}`, payload);
    }

    // MANY OTHER ENDPOINT RELATED STUFFS
};

import Client from '../axios-client';

const resource = '/import/import';

export default {
    uploadFile: function (data) {
        return Client.post(`${resource}/uploadFile`, data,
            {
                headers: { 'Content-Type': 'multipart/form-data' }
            }
        )},
    booked(id) {
        return Client.put(`${resource}/booked/${id}`);
    },
    getGrid(parameters) {
        return Client.get(`${resource}/grid` + parameters);
    },
    getWithCountOperations(id) {
        return Client.get(`${resource}/${id}`);
    },
};

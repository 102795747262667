import Client from '../axios-client';

const resource = '/message/preparation/message';

export default {
  createDraft(payload) {
    return Client.post(`${resource}`, payload);
  },
  changeDraft(id, payload) {
    return Client.put(`${resource}/${id}`, payload);
  },
  removeDraft(id, version) {
    return Client.delete(`${resource}/${id}?version=${version}`);
  },
  sendMessage(id, payload) {
    return Client.put(`${resource}/${id}/send`, payload);
  },
  addRecipientToDraft(id, payload) {
    return Client.post(`${resource}/${id}/recipient`, payload);
  },
  removeAddedRecipients(id, payload) {
    return Client.delete(`${resource}/${id}/recipient`, {data: payload});
  },
  findAllRecipientsByMessageId(id) {
    return Client.get(`${resource}/${id}/recipient`);
  },
  getGrid(parameters, payload) {
    return Client.post(`${resource}/grid` + parameters, payload);
  },
  getItem(id) {
    return Client.get(`${resource}/${id}`);
  },
  countUnread() {
    return Client.get(`${resource}/count-unread`);
  },
  findAllUnreadMessagesIds() {
    return Client.get(`${resource}/list-unread`);
  },
  getUrlForAddAttachmentToDraftMessage: function (id) {
    return Client.defaults.baseURL + `${resource}/${id}/attachment`;
  },
  addAttachmentToDraftMessage: function (payload, id) {
    return Client.post(
      `${resource}/${id}/attachment`,
      payload,
      {
        headers: {'Content-Type': 'multipart/form-data'}
      }
    );
  },
  removeAttachmentFromDraftMessage(id, attachment, version) {
    return Client.delete(`${resource}/${id}/attachment/${attachment}?version=${version}`);
  },
  getAttachment(messageId, attachmentId) {
    return Client.get(`${resource}/${messageId}/attachment/${attachmentId}/download`,
      {
        responseType: 'blob',
      }
    )
  },
  getReplyAttachment(messageId, attachmentId, replyId) {
    return Client.get(`${resource}/${messageId}/reply/${replyId}/attachment/${attachmentId}/download`,
        {
          responseType: 'blob',
        }
    )
  },
  sendReply(id, payload) {
    return Client.post(
        `${resource}/${id}/reply`,
        payload,
        {
          headers: {'Content-Type': 'multipart/form-data'}
        }
    );
  },
  markAsRead(messageId) {
    return Client.put(`${resource}/${messageId}/mark-as-read`);
  },
  markAsUnread(messageId) {
    return Client.put(`${resource}/${messageId}/mark-as-unread`);
  },
  getCountGuardiansReadMessage(id) {
    return Client.get(`${resource}/${id}/count-guardian-read`);
  },
};

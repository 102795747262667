import Client from '../axios-client';

const resource = '/application/beneficiary';

export default {
  getAllQuickSearch() {
    return Client.get(`${resource}`);
  },
  getItem(beneficiaryId) {
    return Client.get(`${resource}/${beneficiaryId}`);
  }
};

import Client from '../axios-client';

const resource = '/subaccount/wallet';

export default {
    payIn(payload) {
        return Client.post(`${resource}/payin`, payload);
    },
    payOut(payload) {
        return Client.post(`${resource}/payout`, payload);
    },
    correctAmountOperation(uuid, uuidWallet, payload) {
        return Client.put(`${resource}/${uuidWallet}/correctAmountOperation/${uuid}`, payload);
    },
    correctTimeOperation(uuid, uuidWallet, payload) {
        return Client.put(`${resource}/${uuidWallet}/correctTimeOperation/${uuid}`, payload);
    },
    correctDetailsOperation(uuid, uuidWallet, payload) {
        return Client.put(`${resource}/${uuidWallet}/correctDetailsInfo/${uuid}`, payload);
    },
    getGridOperations(parameters) {
        return Client.get(`${resource}/operations/grid` + parameters);
    },
    getGridOperationsExcel: function (parameters) {
        return Client.get(`${resource}/operations/excel` + parameters,
            {
                responseType: 'blob',
                headers: {'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
            }
        )
    },
};

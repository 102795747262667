import Client from '../axios-client';

const resource = '/import/importOperation';

export default {
    assignToSubaccount(payload, id) {
        return Client.put(`${resource}/assignToSubaccount/${id}`, payload);
    },
    getGrid(parameters) {
        return Client.get(`${resource}/grid` + parameters);
    },
};

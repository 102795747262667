import Client from '../axios-client';

const resource = '/application/application';

export default {
  create(payload) {
    return Client.post(`${resource}`, payload);
  },
  remove(applicationId) {
    return Client.delete(`${resource}/${applicationId}`);
  },
  changeNumber(applicationId, payload) {
    return Client.put(`${resource}/${applicationId}/change-number`, payload);
  },
  changeComment(applicationId, payload) {
    return Client.put(`${resource}/${applicationId}/change-comment`, payload);
  },
  addInvoiceToApplication(applicationId, payload) {
    return Client.post(`${resource}/${applicationId}/invoices`, payload);
  },
  removeInvoiceFromApplication(applicationId, invoiceId, version) {
    return Client.delete(`${resource}/${applicationId}/invoices/${invoiceId}?version=${version}`);
  },
  completeRoadCard(applicationId, payload) {
    return Client.put(`${resource}/${applicationId}/complete-roadcard`, payload);
  },
  changeApplicationStatus(applicationId, payload) {
    return Client.put(`${resource}/${applicationId}/change-status`, payload);
  },
  getItem(applicationId) {
    return Client.get(`${resource}/${applicationId}`);
  },
  getPDF: function (applicationId) {
    return Client.get(`${resource}/${applicationId}/pdf`,
      {
        responseType: 'blob',
        headers: { 'Accept': 'application/pdf' }
      }
    )},
};
